//React
import { useParams } from "react-router";
import { useEffect, useState } from "react";
//Components
import { TGButton, TGPage, TGText } from "../components";
//Helpers
import { useTranslation } from "react-i18next";
import { getInvoiceDetail } from "../helpers";
import {
  Header,
  Pricing,
  Summary,
  Vehicle,
} from "../components/Invoices";
import { useSelector } from "react-redux";

const AddressStyle = {
  color: "var(--tg-secondary-text)",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 400,
  width: "100%",
  textTransform: "unset",
  lineHeight: "15px",
}

const InvoiceDetail = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [invoice, setInvoice] = useState({});
  const {company: {address, name, tel, mail}} = useSelector(state => state.config)


  const getDetail = async (id) => {
    const result = await getInvoiceDetail(id)
    setInvoice(result);
  };

  useEffect(() => {
    getDetail(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TGPage
      toolbar={true}
      backButton={true}
      title={t("invoice-detail")}
      cssClass={"InvoiceDetail"}
    >
      <Header id={invoice?.payment_id} isBusiness={invoice?.usage_type} />

      <Summary
        createdAt={invoice?.created_at}
        station={{
          name: invoice?.location_name,
          station_id: invoice?.location_id
        }}
        summary={{
          charging_duration: invoice?.charging_duration,
          consumed_amount: invoice?.charging_electricity_consumption,
          charging_fee: invoice?.charging_price
        }}
      />

      <Vehicle
        displayName={invoice?.name}
        plate={invoice?.plate}
        modelText={invoice?.vehicle}
      />

      <Pricing
        chargingCost={invoice?.charging_session_cost}
        occupationCost={invoice?.occupation_price}
        totalCost={invoice?.total_amount}
        statusName={invoice?.status_name}
        paymentType={invoice?.payment_type}
      />
      {/*
      TODO: this component will be updated when the data is ready
      <CardSummary isLoading={isLoading}/>
      */}
      <TGText styles={{...AddressStyle}}>{name}</TGText>
      <TGText styles={{...AddressStyle}}>{tel} - {mail}</TGText>
      <TGText styles={{...AddressStyle}} margin={'0 0 30px 0'}>{address}</TGText>
      {invoice?.detail?.invoice_download ? (
        <TGButton margin={"11px 0"}>{t("download-pdf")}</TGButton>
      ) : null}
    </TGPage>
  );
};

export default InvoiceDetail;
