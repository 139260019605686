import {TGIcon, TGText} from '../TGElements'
import {useSelector} from "react-redux";
import {MasterCard, Visa} from "../../assets/images";
import TGImg from "../TGElements/TGImg";
import React from "react";
import {t} from 'i18next'

const Card = ({type}) => {
    const {paymentMethods,balance} = useSelector(state => state.user)
    const defaultCard = paymentMethods?.find(card => card.default === 1);

    // credit card
    if (type === 1) {
        return (
            <div className="ReceiptCard">
                <TGImg width={42} src={defaultCard?.card?.brand === "MasterCard" ? MasterCard : Visa}/>
                <TGText fontSize={16} fontWeight={"medium"}>
                    **** **** **** {defaultCard?.card?.last4}
                </TGText>
            </div>
        )
    }

    // wallet
    if (type === 2) {
        return (
            <div className="ReceiptCard">
                <TGIcon name={"small-wallet"} width={37} height={32}/>
                <div>
                    <TGText fontSize={16} fontWeight={500} margin={"0 0 2px 0"}>{t('energy-wallet')}</TGText>
                    <TGText fontSize={20} fontWeight={500} textTransform={"none"}><b>{balance}</b> kw</TGText>
                </div>
            </div>
        )
    }

    return null
}

export default Card