import { memo } from "react"
import Icons from "../../assets/icons.svg"

const TGIcon = memo(({ name, fill, size, height, width, margin, cssClass, style, ...props }) => (
  <svg
    className={`icon icon-${name}${cssClass ? " " + cssClass : ""}`}
    fill={fill ? fill : "var(--tg-secondary)"}
    height={height ? height : size ? size : 24}
    width={width ? width : size ? size : 24}
    style={{ margin: margin ? margin : "" , ...style }}
    {...props}
  >
    <use xlinkHref={`${Icons}#icon-${name}`} />
  </svg>
))

export default TGIcon