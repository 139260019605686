export const chartOptions = (translate) => {
    return {
      chart: {
        toolbar: {
          show: false
        },
        stacked: true,
        height: 350,
        zoom: {
          enabled: false
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.45,
          opacityTo: 0.05,
          stops: [20, 100, 100, 100]
        }
      },
      stroke: {
        curve: 'smooth',
      },
      tooltip: {
        enabled: true,
        custom: function ({dataPointIndex, w}) {
          let tooltipContent = `<div style="padding: 10px; background: #fff; border: 1px solid #ddd;">`;

          w.config.series.forEach((seriesItem) => {
            const seriesName = seriesItem.name;
            const seriesValue = seriesItem.data[dataPointIndex][1];
            tooltipContent += `<b>${seriesName}:</b> ${seriesValue}<br/>`;
          });

          tooltipContent += `</div>`;

          return tooltipContent;
        }
      },
      yaxis: {
        show: true,
        showAlways: false,
        showForNullSeries: true,
        decimalsInFloat: 2,
        title: {
          text: 'kwh',
        },
        labels: {
          style: {
            color: "#8e8da4"
          },
          offsetX: 0
        },
      },
      xaxis: {
        tickAmount: 8,
        title: {
          text: translate("consumption-amount"),
        },
        labels: {
          style: {
            color: "#8e8da4"
          },
          formatter: function (val) {
            return (val).toFixed(2);
          }
        },
      }
    }
}