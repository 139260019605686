//Components
import {TGText} from "../TGElements";
import ActionButton from "./ActionButton";
//Helpers
import {useTranslation} from "react-i18next";
import {env} from "../../helpers";
import Card from "./Card";
import {DigitalCodeWrapper} from "../ActiveSession/Session/Partials";
import {format} from "date-fns";

const Content = ({receipt}) => {
    const {t} = useTranslation();

    const formatDuration = (time) => {
        const timeArray = time.split(":");
        const timeUnitArray = ["hourUnit", "minuteUnit", "secondUnit"]
        let result = ""
        timeArray.forEach((time, index) =>
            Number(time) !== 0 ? (result += `${time}${t(timeUnitArray[index])} `) : ""
        );
        return result
    };


    return (
        <div className="ReceiptContent">
            <div>
                <TGText fontWeight={"semibold"} fontSize={18} margin={5}>
                    {t("charge-summary")}
                </TGText>
                <div style={styles.receiptHeaderItem}>
                    <TGText
                        color={"var(--tg-secondary-text)"}
                        fontSize={16}
                        fontWeight={"medium"}
                    >
                        {t("estimated-price")}
                    </TGText>
                    <TGText fontWeight={"bold"} fontSize={24}>
                        {receipt?.cost ? receipt?.cost + env('CURRENCY_SYMBOL') : t("free")}
                    </TGText>
                </div>
                <div style={{...styles.receiptItem, marginTop: 8}}>
                    <TGText
                        color={"var(--tg-secondary-text)"}
                        fontSize={8}
                        textAlign={"center"}
                        fontWeight={"medium"}
                    >
                        <span style={{color: "red"}}>* </span>{t("estimated-price-description")}
                    </TGText>
                </div>
                <div className="ReceiptLine">
                    <hr/>
                </div>
                <div style={{width: "100%"}}>
                    <TGText
                        color={"var(--tg-secondary-text)"}
                        fontSize={14}
                    >
                        {t("charging-station")}
                    </TGText>
                    <TGText fontWeight={"semibold"} fontSize={16} margin={"3px 0 0 0"}>
                        {receipt?.stationName}
                    </TGText>
                </div>
                <div style={styles.receiptItem}>
                    <div style={{width: 150}}>
                        <TGText
                            color={"var(--tg-secondary-text)"}
                            fontSize={14}
                            margin={'0 0 5px'}
                        >
                            {t("socket-no")}
                        </TGText>
                        <TGText fontWeight={"semibold"} fontSize={16}>
                            {receipt?.connector}
                        </TGText>
                    </div>
                    <div style={{flex: 1}}>
                        <TGText
                            color={"var(--tg-secondary-text)"}
                            fontSize={14}
                            margin={'0 0 5px'}
                        >
                            {t("date")}
                        </TGText>
                        <TGText fontWeight={"semibold"} fontSize={16}>
                            {format(new Date(receipt?.endedAt), "dd.MM.yyyy")} <span style={styles.receiptDateSpan}>{format(new Date(receipt?.endedAt), "HH:mm")}</span>
                        </TGText>
                    </div>
                </div>
                <div style={styles.receiptItem}>
                    <div style={{width: 150}}>
                        <TGText
                            color={"var(--tg-secondary-text)"}
                            fontSize={14}
                            margin={'0 0 5px'}
                        >
                            {t("charge-time")}
                        </TGText>
                        <TGText fontWeight={"semibold"} fontSize={16} textTransform="unset">
                            {receipt?.duration ? formatDuration(receipt?.duration) : null}
                        </TGText>
                    </div>
                    <div style={{flex: 1}}>
                        <TGText
                            color={"var(--tg-secondary-text)"}
                            fontSize={14}
                            margin={'0 0 5px'}
                        >
                            {t("vehicle")}
                        </TGText>
                        <TGText fontWeight={"semibold"} fontSize={16}>
                            {`${receipt?.vehicle}`}
                        </TGText>
                    </div>
                </div>
                <div style={styles.receiptItem}>
                    <div style={{width: 150}}>
                        <TGText
                            color={"var(--tg-secondary-text)"}
                            fontSize={14}
                            margin={'0 0 5px'}
                            textTransform={"unset"}
                        >
                            {t("unit-price")}
                        </TGText>
                        <TGText fontWeight={"semibold"} fontSize={16} styles={{display: "flex",alignItems: "center"}}>
                            {receipt?.unitPrice}₺ <DigitalCodeWrapper type={receipt?.digitalCodeTypeId} digitalPrice={receipt?.digitalCodePrice} />
                        </TGText>
                    </div>
                    {receipt?.consumption && (
                        <div style={{flex: 1}}>
                            <TGText
                                color={"var(--tg-secondary-text)"}
                                fontSize={14}
                                margin={'0 0 5px'}
                            >
                                {t("total-consumption")}
                            </TGText>
                            <TGText fontWeight={"semibold"} fontSize={16} textTransform="unset">
                                {receipt?.consumption} kWh
                            </TGText>
                        </div>
                    )}
                </div>

                <Card type={receipt?.paymentMethodType}/>

                {receipt?.isOccupied && (
                    <TGText
                        color={"var(--tg-secondary-text)"}
                        fontSize={10}
                        textAlign={"center"}
                        fontWeight={"medium"}
                    >
                        <span style={{color: "red"}}>* </span> {t("active-occupation-warning")}
                    </TGText>
                )}

                <ActionButton/>
            </div>
        </div>
    );
};

const styles = {
    receiptItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        marginTop: 20,
    },
    receiptHeaderItem: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 10,
        marginTop: 10,
    },
    receiptDateSpan: {
        borderLeft: "1px solid var(--tg-secondary-text)",
        color: "var(--tg-secondary-text)",
        paddingLeft: 5,
    }
}

export default Content;
