import {useHistory} from "react-router";
import {resetSlice, setUser} from "../redux/slices/userSlice";
import {useDispatch} from "react-redux";
import {getUser} from "../helpers";
import {useEffect} from "react";
import {store} from "../redux/store";

const LoginWithToken = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const search = history?.location?.search
    const params = new URLSearchParams(search)

    useEffect(() => {
        if (params.get("token") && params.get("id")) {
            store.dispatch(resetSlice())
            localStorage.clear()
            const token = params.get("token")
            const id = params.get("id")
            const user = {id,token}
            let stringUserObj = JSON.stringify(user)
            localStorage.setItem("user", stringUserObj)
            dispatch(setUser(user))
            getUser(false).then((res) => {
                if(res.status === 200 && res.data.data[0].email){
                    history.push('/map')
                }else{
                    history.push('/menu/edit-user')
                }
            })
        }
    }, []);

};

export default LoginWithToken;