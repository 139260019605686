import {TGIcon, TGText} from "../TGElements";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useClickOutSide} from "../../hooks";
import {setDefaultMyCompany} from "../../helpers";

const CompaniesListItem = ({company, deleteHandler, getCompany}) => {
    const {t} = useTranslation()
    const [showActionSheet, setShowActionSheet] = useState(false);
    const ref = useClickOutSide(() => setShowActionSheet(false));

    const moreButtonHandler = () => {
        setShowActionSheet(!showActionSheet);
    }

    const setDefaultCompany = () => {
        setDefaultMyCompany(company.id).then(() => {
            getCompany();
            setShowActionSheet(false)
        })
    }


    return (
        <div className={"companies-list-item-wrapper"}>
            <div>
                <div className={"companies-list-item"}>
                    <TGText fontWeight={"semibold"} wrapWithDots={true}>{t("company-name")}</TGText>
                    <TGText wrapWithDots={true}>{company.company.company_name || "-"}</TGText>
                </div>

                <div className={"companies-list-item"}>
                    <TGText fontWeight={"semibold"} wrapWithDots={true}>{t("tax-number")}</TGText>
                    <TGText wrapWithDots={true}>{company.company.company_code}</TGText>
                </div>
            </div>

            <div className={'companyDefaultButton'} ref={ref}>
                <div
                    className={"moreButton"}
                    onClick={moreButtonHandler}
                >...
                </div>
                <TGIcon
                    name={company.is_default ? 'star-fill-green' : 'star-outline'}
                    width={16}
                    height={17}
                />
                <div className={`dropdown ${showActionSheet && 'active'}`}>
                    <p onClick={() => deleteHandler(company.company_id)}> {t('delete')} </p>
                    <p onClick={setDefaultCompany}> {t('make-default')} </p>
                </div>
            </div>
        </div>
    );
}

export default CompaniesListItem;
