import { handleError } from "./handleError";
import { validatePhone } from 'react-international-phone';
const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;


export const validate = {
    name: ({value: name, type}) => {
        if (name?.length < 2 || regex.test(name)) {
            return handleError(type, "invalid-name", false);
        }
        return handleError(type);
    },
    surname:({value: surname, type}) => {
        if (surname?.length < 2 || regex.test(surname)) {
            return handleError(type, "invalid-surname", false);
        }
        return handleError(type);
    },
    email: ({value: email, type}) => {
        if(regex.test(email)) {
            return handleError(type)
        }
        return handleError(type, "invalid-email", false);
    },
    phoneNumber: ({value: phoneNumber, type}) => {
        const phoneValidation = validatePhone(phoneNumber)
        if (!phoneValidation.isValid || !phoneValidation.lengthMatch) {
            return handleError(type, "invalid-phone-number", false);
        }
        return handleError(type);
    }
}