import {memo} from 'react'
import GoogleMapReact from "google-map-react";
import MapStyles from "../../theme/MapStyles"
import { useSelector } from 'react-redux';

const MapComponent = memo(({setMap, setMapLoaded, onDragEnd, setCurrentZoom}) => {
    const { api_keys } = useSelector(state => state.config)
    const google_key = api_keys.google_map ?? process.env.REACT_APP_GOOGLE_MAP_KEY
    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: google_key }}
            defaultZoom={12}
            // TODO: Change this to the current location
            defaultCenter={{lat: 41.095379, lng: 28.803251}}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({map, maps}) => {
                setMap({map, maps})
                setMapLoaded(true)
            }}
            onDragEnd={onDragEnd}
            onZoomAnimationEnd={setCurrentZoom}
            options={{
                styles: MapStyles,
                zoomControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                cameraControl: false,
            }}
        />
    )
})

export default MapComponent
