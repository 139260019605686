import { useSelector } from "react-redux";
import { TGIcon, TGText } from "../../TGElements";
import { useTranslation } from "react-i18next";
import { formatSeconds } from "../../../helpers";
import {DigitalCodeWrapper} from "../Session/Partials";
import React from "react";

const SessionInformation = () => {
  const { t } = useTranslation();
  const {activeSession} = useSelector(state => state.session)
  return (
    <div
      style={{
        marginTop: 34,
      }}
    >
      <div style={{
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        margin: "0 0 10px 0"
      }}>
        <TGText fontSize={14} fontWeight={"semibold"}>
          {t("charge-informations")}
        </TGText>
      </div>
      <div className="info-section charge-info">
        <div className="info-line">
          <div>
            <TGIcon name="charge-speed" size={20} />
            <TGText fontSize={16} fontWeight={"regular"}>
              {t("charge-speed")}
            </TGText>
          </div>
          <TGText fontSize={16} fontWeight={"medium"} textTransform={"unset"}>
            {activeSession.kwh} kw/H
          </TGText>
        </div>
        <div className="info-line">
          <div>
            <TGIcon name="charge-time" size={20} />
            <TGText fontSize={16} fontWeight={"regular"}>
              {t("charge-time")}:
            </TGText>
          </div>
          <TGText fontSize={16} fontWeight={"medium"}>
          {formatSeconds(activeSession.duration)}
          </TGText>
        </div>
        <div className="info-line">
          <div>
            <TGIcon name="mono-charge-20" size={20.5} />
            <TGText fontSize={16} fontWeight={"regular"}>
              {t("charge-fee")}:
            </TGText>
          </div>
          <span style={{display: 'flex', alignItems: 'center'}}>
            <TGText fontSize={16} fontWeight={"medium"}>
              {activeSession.unit_price}₺
            </TGText>
            <DigitalCodeWrapper/>
          </span>
        </div>
        {activeSession?.battery_percent ?
        <div className="info-line">
          <div>
            <TGIcon name="mono-battery" size={20.5} />
            <TGText fontSize={16} fontWeight={"regular"}>
              {t("battery-percent")}:
            </TGText>
          </div>
          <TGText fontSize={16} fontWeight={"medium"}>
            {activeSession.battery_percent}%
          </TGText>
        </div> : null}
      </div>
    </div>
  );
};

export default SessionInformation;
