import {useSelector} from "react-redux";
import {Header, SocketTypes, ActionButton, Prices, Address, FastCharge, ComingSoon} from "./components";
import {IonChip} from "@ionic/react";
import {t} from "i18next";

const Content = ({setExpandSocketTypesList, expandSocketTypesList}) => {
  const {currentStation} = useSelector(state => state.station)
  const isScrollMargin = !(currentStation?.DETAIL?.connections?.length <= 6)
  const isActive = currentStation?.is_operational
  const isOpen = currentStation?.open

  return (
    <div style={{
      paddingTop: 24,
      paddingLeft: 8,
      marginBottom: (isScrollMargin && expandSocketTypesList) ? "100px" : 0,
      mixBlendMode: !isOpen && "luminosity",
    }}>
      {!isOpen && (
        <IonChip
          style={{
            'margin-inline-start': 0,
            marginBottom: '20px',
          }}
        >
          {t('station-close-text')}
        </IonChip>
      )}
      <Header/>
      <FastCharge/>
      <SocketTypes
        expandList={expandSocketTypesList}
        setExpandList={setExpandSocketTypesList}
      />
      {isActive ? (
        <>
          <ActionButton/>
          <Prices/>
          <Address/>
        </>
      ) : <ComingSoon/>}
    </div>
  )
}

export default Content