import React from 'react';
import {useSelector} from "react-redux";
import {t} from "i18next"

const DigitalCodeWrapper = ({type,digitalPrice}) => {
    const {activeSession} = useSelector(state => state.session)
    const typeId = activeSession?.digital_code_type_id?? type
    const price = activeSession?.digital_code_price?? digitalPrice

    if (!typeId || !price) {
        return null;
    }

    if (typeId === 1) {
        return <span style={styles.digitalCodeWrapper}>
            {t('fixed_price')}
        </span>
    }

    return (
        <span style={styles.digitalCodeWrapper}>
            {price} {t('percentage_discount')}
        </span>
    );
};


const styles = {
    digitalCodeWrapper: {
        backgroundColor: 'var(--tg-dark)',
        color: 'var(--tg-white)',
        borderRadius: 4,
        padding: '0 4px',
        fontSize: 10,
        lineHeight: '15px',
        textTransform: 'lowercase',
        marginLeft: 4
    }
}

export default DigitalCodeWrapper;