import {memo, useState} from "react";
import {TGImg} from "../TGElements";
import {updateProfilePicture} from "../../helpers";
import {useSelector} from "react-redux";
import Compressor from "compressorjs";
import { Alert } from "../../helpers/alert";
import { useTranslation } from "react-i18next";

const SetProfileImage = memo(() => {
    const {t} = useTranslation()
    const {user} = useSelector(state => state.user);
    const [image, setImage] = useState(user?.info?.profile_photo_url);

    const uploadProfileImage = (image) => {
        updateProfilePicture([image]).then(({data}) => {
            if (!data.success) return;

            if (FileReader && image) {
                let fr = new FileReader();
                fr.onload = function () {
                    setImage(fr.result);
                };
                fr.readAsDataURL(image);
            }
        });
    }

    const handleCompressedUpload = (e) => {
        const allowedTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/webp'];
        const image = e.target.files[0];
        const fileOrBlob = image instanceof File || image instanceof Blob
        if(allowedTypes.includes(image.type) && fileOrBlob){
            new Compressor(image, {
                quality: 0.8,
                success: (compressedResult) => {
                    const image = new File([compressedResult], compressedResult.name);
                    uploadProfileImage(image)
                },
            });
        }else {
            Alert.error(t('img-type-is-not-allowed'))
        }
    };

    const Preview = () => (
        <TGImg id="profile_photo" src={image} alt="ProfileImage" type={"avatar"} size={100} additionalStyles={{objectFit: 'cover'}}/>
    )

    return (
        <label className="SetupProfileImageWrapper" htmlFor="uplad">
            <input id="uplad" type="file" accept="image" className="PPInput" onChange={handleCompressedUpload}/>
            {image ? <Preview/> : <span className="SetUpProfileImageLabel"/>}
            <span className="SetUpProfileImageLabelCamera"/>
        </label>
    );
})

export default SetProfileImage;
