import React, {useEffect, useState} from 'react';
import {TGIcon, TGPage, TGText} from "../components";
import {api} from "../helpers";
import {TGVirtualScroll} from "../components/TGElements";
import {format} from "date-fns";
import {enGB, tr} from "date-fns/locale";
import {useTranslation} from "react-i18next";
import Chart from "react-apexcharts";
import {chartOptions} from "../helpers/chartOptions";

const ChargingHistory = () => {
  const {t, i18n} = useTranslation()
  const [historyData, setHistoryData] = useState([])
  const [chartTransactionId, setChartTransactionId] = useState(null)
  const [chartData, setChartData] = useState([])

  const getChargeHistory = async () => {
    // API call
    const res = await api.getChargeHistoryWithStation()
    if (res.status === 200) {
      setHistoryData(res.data.response)
    }
  }

  const getChartData = async (transactionId) => {
    // API call
    const res = await api.getChartByTransactionId(transactionId)
    if (res.status === 200) {
      setChartData(res.data.response)
    }
  }


  useEffect(() => {
    getChargeHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clickHandle = (event, data) => {
    setChartData([])
    getChartData(data.id)
    if (chartTransactionId === data.id) {
      setChartTransactionId(null)
    } else {
      setChartTransactionId(data.id)
    }
  }


  return (
    <TGPage
      title={t("charging-history")}
      backButton={true}
      scrollY={true}
    >

      <TGText fontWeight={600} fontSize={18} textTransform={"none"} margin={'20px 0 10px'}>
        {t('last-operations')}
      </TGText>

      <div className="ChargingHistoryWrapper">
        <TGVirtualScroll
          data={historyData}
          item={(index, data) => (
            <>
              <div key={index} onClick={(event) => clickHandle(event, data)}
                   className={`ChargingHistoryItemWrapper ${data.id === chartTransactionId && 'open'}`}>
                <div className="detail">
                  <div className={"iconWrapper"}>
                    <TGIcon name={"charge-transaction"} size={18} fill={"#18C941"}/>
                  </div>
                  <div className={"center-side"}>
                    <TGText wrapWithDots={true} fontSize={14} fontWeight={500} margin={'0 0 4px'}>
                      {data.station_name}
                    </TGText>
                    <div className={"transaction-wrapper"}>
                      <TGText fontSize={14} fontWeight={500} color={'var(--tg-secondary-text)'}>
                        {data.connection_type}
                      </TGText>
                      <span className={"transaction-dot"}></span>
                      <TGText fontSize={14} fontWeight={500} color={'var(--tg-primary-text)'}>
                        {format(data.transaction_date, "d MMM-kk:mm", {locale: i18n.language === 'tr' ? tr : enGB})}
                      </TGText>

                    </div>
                  </div>
                  <div className={"right-side"}>
                    <TGText textAlign={"end"} width={'100%'} margin={'0 0 4px'} fontSize={16}
                            fontWeight={600}>
                      {data.total_price}₺
                    </TGText>
                    <TGText fontSize={14} fontWeight={500} color={'var(--tg-secondary-text)'}>
                      {data.total_consumption} kWh
                    </TGText>
                  </div>
                </div>
              </div>


              {data.id === chartTransactionId && (
                <Chart
                  options={chartOptions(t)}
                  series={[
                    {
                      data: chartData?.consumptions
                    }
                  ]}
                  type="area"
                  width="100%"
                />
              )}
            </>
          )}
        />
      </div>
    </TGPage>
  );
};

export default ChargingHistory;