import InvoiceItem from "./InvoiceItem"
import {Modals, NoEntries} from ".."
import InvoiceSkeleton from "./InvoiceSkeleton"

import {api, getInvoiceList} from "../../helpers"
import React, {useEffect, useState} from "react"
import {TGVirtualScroll} from "../TGElements"
import {t} from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {setCheckUserModal} from "../../redux/slices/modalSlice";
import {Alert} from "../../helpers/alert";
import InvoiceDeptHeader from "./InvoiceDeptHeader";

const InvoiceList = ({isLoading, lastPage}) => {
    const [invoices, setInvoices] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    const getInvoices = () => {
        getInvoiceList(false, {}, currentPage)
            .then((result) => {
                setInvoices(prevInvoices => {
                    return [...prevInvoices, ...result.invoices];
                });
            })
    };

    useEffect(() => {
        getInvoices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])


    if (isLoading) {
        return <InvoiceSkeleton/>
    }


    return (
        <div className="InvoiceList">
            <InvoiceDeptHeader getInvoices={getInvoices} setInvoices={setInvoices} />
            {invoices?.length ? (
                <TGVirtualScroll
                    data={invoices}
                    item={(idx, invoice) => <InvoiceItem invoice={invoice} key={idx}/>}
                    handleEnd={() => currentPage < lastPage && setCurrentPage(prev => prev + 1)}
                />
            ) : (
                <NoEntries path={"invoices"}/>
            )}
            <Modals />
        </div>
    );
}

export default InvoiceList
