import {useTranslation} from "react-i18next";
import {TGBackButton, TGButton, TGIcon, TGPage, TGText} from "../TGElements";
import {BarcodeScanner} from '@capacitor-community/barcode-scanner';
import {useDispatch, useSelector} from "react-redux";
import {setFlashlight, setScanner} from "../../redux/slices/deviceSlice";
import {getStationDetailByCode, startCharge} from "../../helpers";
import {useHistory} from "react-router-dom";
import {
    useIonRouter,
    useIonViewDidEnter,
    useIonViewWillEnter,
    useIonViewWillLeave
} from "@ionic/react";

const Scanner = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const router = useIonRouter()
    const history = useHistory()
    const {flashlight} = useSelector(state => state.device)

    const handleFlash = async (type) => {
        const {isEnabled} = await BarcodeScanner.getTorchState()
        if (isEnabled || type === "close") {
            BarcodeScanner.disableTorch()
            dispatch(setFlashlight(false))
            return;
        }
        BarcodeScanner.enableTorch()
        dispatch(setFlashlight(true))
    }
    const stopScan = async (redirect) => {
        handleFlash("close")
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
        dispatch(setFlashlight(false))
        dispatch(setScanner(false))
    }

    const startScan = async () => {
        BarcodeScanner.prepare()
        BarcodeScanner.hideBackground();
        await BarcodeScanner.startScanning('', async (result) => {
            if (result.hasContent) {
                let content = result.content;
                if (content.includes("http")) {
                    content = content.split("/").pop();
                }
                if (!content) {
                    return history.goBack()
                }
                const isExistConnection = await getStationDetailByCode(content)
                if (!isExistConnection) {
                    return history.goBack()
                }
                const {start, isCableConnected} = await startCharge(content)
                if (start) {
                    stopScan()
                    router.push("/active-session", 'forward', 'pop', {isCableConnected: isCableConnected})
                }
            }
        });
    };

    useIonViewDidEnter(() => {
        document.body.classList.add("scanner-active");
    },[])


    useIonViewWillEnter(() => {
        startScan()
    },[])

    useIonViewWillLeave(() => {
        document.body.classList.remove("scanner-active");
        stopScan()
    },[])

    return (
        <TGPage cssClass={"ScannerPage"} toolbar={false} ionPadding={false} scrollY={false} fullscreen={true}>
            <div className='scanner-ui'>
                <div className={`background${flashlight ? "-flash-active" : ""}`}/>
                <div className="outline">
                    <span className="frame-left-bottom"/>
                    <span className="frame-right-bottom"/>
                    <span className="frame-left-top"/>
                    <span className="frame-right-top"/>
                    <span className="scan-animated"/>
                </div>
                <TGBackButton type="secondary"/>
                <TGText
                    color={"var(--tg-white)"}
                    cssClass="scanText"
                    margin={"0 auto"}
                >

                    {t("scan-qr-and-start-charge")}
                </TGText>
                <TGButton margin={"0 auto"} cssClass={"EnterCodeButton"} width={200} onButtonClick={() => {
                    history.push("/start-session")
                }}>
                    {t("enter-code")}
                </TGButton>
                <TGButton
                    backgroundColor={"var(--tg-transparent)"}
                    backgroundActiveColor={"var(--tg-transparent)"}
                    width={"fit-content"}
                    onButtonClick={() => handleFlash()}
                    cssClass="FlashButton"
                    margin={"0 auto"}
                >
                    <TGText
                        color={"var(--tg-white)"}
                        margin={"0 15px 0 0"}
                    >
                        {t(`${flashlight ? "close" : "open"}-flash`)}
                    </TGText>
                    <TGIcon name={`${flashlight ? "close" : "open"}-flash`} size={34}/>
                </TGButton>
            </div>
        </TGPage>
    );
};

export default Scanner;
