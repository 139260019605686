import React, {useEffect, useState} from 'react';
import {t} from "i18next";
import {api} from "../../helpers";
import {setCheckUserModal} from "../../redux/slices/modalSlice";
import {Alert} from "../../helpers/alert";
import {useDispatch} from "react-redux";

const InvoiceDeptHeader = ({setInvoices, getInvoices}) => {
    const [unpaidExist, setUnpaidExist] = useState(null)
    const dispatch = useDispatch()
    const [occupation, setOccupation] = useState(false)

    const getOccupation = () => {
        api.getOccupation().then((res) => {
            if (res?.data?.success) {
                setOccupation(true)
                return
            }
            setOccupation(false)
        });
    }


    const getDept = () => {
        api.getUserDept().then((res) => {
            if (res?.data?.response?.debt) {
                setUnpaidExist(res.data.response)
                return
            }
            setUnpaidExist(false)
        });
    }

    const payAll = () => {
        api.checkUserAbleToStartSession(true, true, false).then((response) => {

            // payment method not found
            if (!response?.data?.response.paymentMethod) {
                dispatch(setCheckUserModal({debt: false, vehicle: false, paymentMethod: true}))
                return;
            }

            if (response?.data?.response.dept_description) {
                Alert.error(response.data.response.dept_description)
                return;
            }

            // success payment
            if (response?.data?.response.debt) {
                setInvoices([]);
                getInvoices();
                setUnpaidExist(null);
            }

        })
    };

    useEffect(() => {
        getOccupation()
        getDept()
    }, []);

    if (!unpaidExist) {
        return null
    }



    return (
        <div>
            {occupation ? (
                <div className={"ActiveOccupationText"}>
                    <p>{t('active-occupation')}</p>
                </div>
            ) : (
                <div>
                    <div className="PayAllTextWrapper">
                        <p>{unpaidExist.message}</p>
                    </div>
                    <button onClick={payAll} className="TGButton PayAllButton">
                        {t("pay-all-debt")}
                    </button>
                </div>
            )}
        </div>
    );
};

export default InvoiceDeptHeader;