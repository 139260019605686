import {memo} from "react";

const TGText = memo(({children, width, color, margin, padding, textAlign, fontSize, fontWeight, textDecoration, textTransform, cssClass, wrapWithDots, styles, reference, ...props}) => {
    
    const setFontWeight = (fw) => {
        if(typeof fw !== 'string') return fw
        switch (fw){
            case "thin":
                return 100
            case "extralight":
                return 200
            case "light":
                return 300
            case "regular":
            case "normal":
                return 400
            case "medium":
                return 500
            case "semibold":
                return 600
            case "bold":
                return 700
            case "extrabold":
                return 800
            case "black":
                return 900
            default:
                return 400
        }
    }

    const TGTextStyle = {
        color: color || "var(--tg-primary-text)",
        margin: margin || "0px",
        padding: padding || "0px",
        textAlign: textAlign || "left",
        fontSize: fontSize || "18px",
        fontWeight: setFontWeight(fontWeight),
        textTransform: textTransform || "capitalize",
        width: width || "fit-content",
        textDecoration: textDecoration || "none",
        whiteSpace: wrapWithDots ? "nowrap" : "unset",
        overflow: wrapWithDots ? "hidden" : "unset",
        textOverflow: wrapWithDots ? "ellipsis" : "unset",
        ...styles
    }

    return (
        <p style={TGTextStyle} className={cssClass} ref={reference} {...props}>
            {children}
        </p>
    );
})

export default TGText;
